import { template as template_b95e28bae86d4ea2bb6a6906cd0f5ec8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_b95e28bae86d4ea2bb6a6906cd0f5ec8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
