import { template as template_1c2a27c0b51744029f1bf3a73238053c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_1c2a27c0b51744029f1bf3a73238053c(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
