import { template as template_bfe4ff20e3a74a4da51d2c9642266d7c } from "@ember/template-compiler";
const FKText = template_bfe4ff20e3a74a4da51d2c9642266d7c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
